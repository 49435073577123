import React, { Component } from 'react';
import QrScanner from 'qr-scanner';
import '../css/escaner.css';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import toast, { Toaster } from 'react-hot-toast';

class Escaner extends Component {
    constructor(props) {
        super(props);
        this.state = {
           scanner: '',
           usuarios: []
        }
    }

    componentDidMount = async () => {        
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ operacion: 'selectUsuarios' })
          };
        const response = await fetch('https://lectortarjetalealtad.argeos.net/php/DB/consultas.php', requestOptions);
        const data = await response.json();
        if (data.error === 0) {
            this.setState({
                usuarios: data.usuarios
            })
        }else{
            toast.error('Error al obtener usuarios.');
        }
        await this.setState({
            scanner: new QrScanner(document.getElementById('qr-video'), 
            result => {
                if (isPossiblePhoneNumber(result.data)) {
                    if (this.state.usuarios.find(e => e.telefono === result.data)) {
                        this.props.setEscaneando();
                        this.state.scanner.stop();
                        this.props.setTelefono(result.data);
                    }else{
                        toast.error('Usuario no registrado.', {
                            id: 'unico',
                          });
                    }               
                }else{
                    toast.error('El QR no es válido.', {
                        id: 'unico',
                      });
                }
            }, 
            {
                highlightScanRegion: true,
            })
        })
      }

      iniciar = () => {
        this.props.setEscaneando();
        this.state.scanner.start();
      }

      cancelar = () => {
        this.props.setEscaneando();
        this.state.scanner.stop();
      }
   
    render() { 
        return ( 
            <>
                <button className={'btn btn-dark py-1 w-50 mx-auto justify-content-center ' + ((this.props.escaneando === true || this.props.telefono !== '') ? 'd-none' : 'd-flex')} style={{ borderRadius: '20px', fontSize: '18px', fontWeight: '500' }} onClick={() => this.iniciar()}>Escanear</button>
                <div id='video-container' hidden={!this.props.escaneando} className="example-style-2 animate__animated animate__fadeInUp">
                    <video id='qr-video' style={{ width: '100%', borderRadius: '15px' }}/>
                </div>
                <button className={'btn btn-info py-1 w-50 mx-auto text-white justify-content-center ' + (!this.props.escaneando ? 'd-none' : 'd-flex')} style={{ borderRadius: '20px', fontSize: '18px', fontWeight: '500', position: 'absolute', bottom: '10px', left: '0', right: '0' }} onClick={() => this.cancelar()}>Cancelar</button>
                <Toaster/>
            </>
         );
    }
}
 
export default Escaner;