import React, { Component } from 'react';
import Escaner from './Escaner';
import ContadorVisitas from './ContadorVisitas';
import { formatPhoneNumber } from 'react-phone-number-input';
import '../css/inicio.css';
import toast, { Toaster } from 'react-hot-toast';
import { Modal } from 'react-bootstrap';

class Inicio extends Component {
  constructor(props) {
    super(props);
    this.state = {
      escaneando: false,
      telefono: '',
      visitas: '',
      visitasTotales: '',
      nombre: '',
      diaCumpleanos: '',
      mesCumpleanos: '',
      codigo: '',
      recompensas: [],
      seeModalRecompensa: false,
      recompensa: [],
      recompensaActiva: []
    }
  }

  componentDidMount = async () => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ operacion: 'selectRecompensas' })
    };
    const response = await fetch('https://lectortarjetalealtad.argeos.net/php/DB/consultas.php', requestOptions);
    const data = await response.json();
    if (data.error === 0) {
      this.setState({
        recompensas: data.recompensas
      })
    }else{
      toast.error('Error al obtener recompensas.');
    }
  }

  onChangeValue = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  setTelefono = async (telefono) => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ operacion: 'selectDatos', telefono: telefono })
    };
    const response = await fetch('https://lectortarjetalealtad.argeos.net/php/DB/consultas.php', requestOptions);
    const data = await response.json();
    if (data.error === 0) {
      let visitasMax = this.state.recompensas[this.state.recompensas.length - 1].noVisitas;
      let visitas = data.data.visitas%visitasMax;
      let recompensaActiva = [];
      if (this.state.recompensas.find(e => Number(e.noVisitas) === (visitas + 1))) {
        recompensaActiva = this.state.recompensas.find(e => Number(e.noVisitas) === (visitas + 1));
      }
      this.setState({
        telefono: telefono,
        visitas: visitas,
        visitasTotales: data.data.visitas,
        nombre: data.data.nombre,
        diaCumpleanos: data.data.diaCumpleanos,
        mesCumpleanos: data.data.mesCumpleanos,
        recompensaActiva: recompensaActiva
      })
    }else{
      toast.error('Error al obtener datos de usuario.');
    }
  }

  setEscaneando = async () => {
    await this.setState(prevState => ({
      escaneando: !prevState.escaneando
    }));
  }

  getMarcarVisita = (index) => {
    let marcarVisita = false;
    if (this.state.visitas === 0 && this.state.visitasTotales > 0) {
      marcarVisita = true;
    }else{      
      marcarVisita = index <= Number(this.state.visitas);
    }
    return marcarVisita
  }

  getRecompensa = (noVisitas) => {
    let recompensas = this.state.recompensas;
    let recompensa = recompensas.find(e => Number(e.noVisitas) === Number(noVisitas));
    return recompensa
  }

  registrar = async (e) => {
    e.preventDefault();
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ operacion: 'verificarCodigo', codigo: this.state.codigo })
    };
    const response = await fetch('https://lectortarjetalealtad.argeos.net/php/DB/consultas.php', requestOptions);
    const data = await response.json();
    if (data.error === 0) {
      if (data.respuesta) {
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ operacion: 'agregarVisita', telefono: this.state.telefono })
        };
        const response = await fetch('https://lectortarjetalealtad.argeos.net/php/DB/consultas.php', requestOptions);
        const data = await response.json();
        if (data.error === 0) {
          toast.success('Visita Agregada');
          this.setTelefono(this.state.telefono);
          this.setState({
            codigo: ''
          })
        }else{
          if (data.error === 3) {
            toast.error(data.mensaje);
          }else{
            toast.error('Error al agregar visita.');
            console.log(data.mensaje);
          }
        }
      }else{
        toast.error('Código incorrecto.');
      }
    }else{
      toast.error('Error al verificar código.');
    }
  }

  abrirModalRecompensa = () => {
    this.setState({
      seeModalRecompensa: true
    })
  }

  cerrarModalRecompensa = () => {
    this.setState({
      seeModalRecompensa: false
    })
  }

  render() { 
    return ( 
    <>
      <div className="container-fluid p-4" style={{ overflowX: 'hidden' }}>
          <div className="card w-100 mx-auto animate__animated animate__zoomIn animate__fast" style={{ backgroundColor: 'rgb(255, 255, 255, 0.7)', border: 'none', borderRadius: '15px', minHeight: window.innerHeight - 50, maxHeight: 'fit-content', boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px', maxWidth: '500px' }}>
              <div className="card-header pt-4 px-4" style={{ backgroundColor: 'transparent', border: 'none' }}>
                  <div className="row m-0">
                      <div className="col-2 p-0">
                          <img src="/img/logo_ODA.svg" alt="logo" className="logo"/>
                      </div>           
                      <div className="col-10 p-0 d-flex align-items-center justify-content-center">
                          <h3 className="m-0" style={{ fontWeight: '600' }}>Programa de lealtad</h3>
                      </div>
                  </div>
              </div>
              <div className="card-body pt-0" style={{ borderBottomLeftRadius: '15px', borderBottomRightRadius: '15px', paddingBottom: '60px' }}>
                <div className="row">
                    <div className="col-12">
                      <Escaner telefono={this.state.telefono} setTelefono={this.setTelefono} escaneando={this.state.escaneando} setEscaneando={this.setEscaneando}/>
                    </div>
                </div>
                {
                  !this.state.escaneando && <>
                    <div className="card tarjetaCliente mx-auto mt-4 animate__animated animate__fadeInUp" hidden={this.state.telefono === ''}>
                      <div className="card-header p-0" style={{ border: 'none', backgroundColor: 'transparent' }}>
                        <div className="row m-0">
                          <div className="col-12 p-0">
                            <p className="m-0" style={{ fontSize: '15px' }}>Ojo de Agua Pachuca</p>
                          </div>
                        </div>
                      </div>
                      <div className="card-body p-0 d-flex align-items-center">
                        <div className="w-100">
                          <div className="row m-0">
                            <div className="col-12 p-0">
                              <h5 className="m-0">{this.state.nombre}</h5>
                            </div>
                          </div>
                          <div className="row m-0 mt-2">
                            <div className="col-12 p-0 text-end">
                              <h6 className="m-0">{formatPhoneNumber(this.state.telefono)}</h6>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card-footer p-0" style={{ border: 'none', backgroundColor: 'transparent' }}>
                        <div className="row m-0">
                          <div className="col-10 p-0 d-flex align-items-end">
                            {
                              this.state.diaCumpleanos !== '' && <p className="m-0">Cumpleaños: {this.state.diaCumpleanos}/{this.state.mesCumpleanos}</p>
                            }
                          </div>
                          <div className="col-2 p-0 text-end">
                            <img src="/img/logo_ODA_Blanco.svg" alt="logo" className="logo"/>
                          </div>
                        </div>
                      </div>
                    </div>
                    <form onSubmit={this.registrar}>
                      <div className="input-group mt-4 mx-auto animate__animated animate__fadeInUp" hidden={this.state.telefono === ''} style={{ maxWidth: '350px' }}>
                        <input type="password" name='codigo' className='form-control' style={{ borderTopLeftRadius: '20px', borderBottomLeftRadius: '20px' }} placeholder='Ingrese código de seguridad' pattern="[0-9]*" inputMode="numeric" required minLength={6} maxLength={6} value={this.state.codigo} autoComplete="off" onChange={this.onChangeValue}/>
                        <div className="input-group-append">
                          <button type='submit' className="btn btn-dark" style={{ borderTopLeftRadius: '0px', borderBottomLeftRadius: '0px', borderTopRightRadius: '20px', borderBottomRightRadius: '20px' }}>Registrar</button>
                        </div>
                      </div>
                    </form>
                    {
                      this.state.recompensaActiva.idRecompensa &&
                        <button className='recompensaActiva mt-4 animate__animated animate__fadeInUp' onClick={this.abrirModalRecompensa}>
                            <p className='m-0 text-center' style={{ fontSize: '16px' }}>¡Tiene una recompensa disponible!</p>
                            <p className='m-0 text-center' style={{ fontSize: '22px' }}>{this.state.recompensaActiva.descripcion}</p>
                            <p className='m-0 text-center mt-2' style={{ fontSize: '12px', fontWeight: '600' }}>Haz click aquí para mas información.</p>
                        </button>
                    }
                    <button className={'btn btn-info py-1 w-50 mx-auto text-white justify-content-center ' + ((this.state.escaneando === false && this.state.telefono !== '') ? 'd-flex' : 'd-none')} style={{ borderRadius: '20px', fontSize: '18px', fontWeight: '500', position: 'absolute', bottom: '10px', left: '0', right: '0', maxWidth: '200px' }} onClick={() => window.location.reload()}>Volver</button>
                    <ContadorVisitas visitas={this.state.visitas} visitasTotales={this.state.visitasTotales} recompensas={this.state.recompensas}/>
                    <Modal show={this.state.seeModalRecompensa} onHide={this.cerrarModalRecompensa} centered className='modalRecompensa'>
                        <Modal.Header className='d-flex justify-content-center align-items-center' style={{ border: 'none' }}>
                            <h5 className='m-0'>Recompensa {this.state.recompensaActiva.noVisitas} visitas</h5>
                        </Modal.Header>
                        <Modal.Body>
                            <p className='m-0'>{this.state.recompensaActiva.descripcion}</p>
                        </Modal.Body>
                        <Modal.Footer className='d-flex justify-content-start'>
                            <p className='m-0' style={{ fontSize: '12px', color: 'gray', fontWeight: '600' }}>{this.state.recompensaActiva.terminos}</p>
                        </Modal.Footer>
                    </Modal>
                    <Toaster/>
                  </>
                }
              </div>

          </div>
      </div>
    </> 
    );
  }
}
 
export default Inicio;


