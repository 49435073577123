import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

class NotFoundRoute extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }
 
    render() { 
        return (  
            <>
                <Redirect to="/"/>
            </>
        );
    }
}
 
export default NotFoundRoute;